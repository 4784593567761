var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"700px","transition":"slide-y-transition"},model:{value:(_vm.addEditDialog),callback:function ($$v) {_vm.addEditDialog=$$v},expression:"addEditDialog"}},[_c('v-card',{staticClass:"rounded-lg dialog-card-border"},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-center font-weight-bold"},[_c('div',{staticClass:"text-center col pa-0"},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('Update Company') : _vm.$t('Add New Company'))+" ")]),_c('v-btn',{attrs:{"fab":"","small":"","depressed":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',{staticClass:"mdil-28px mdil-rotate-45"},[_vm._v(" mdil-plus ")])],1)],1),_c('validation-observer',{ref:"theForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-6",attrs:{"cols":"12"}},[(_vm.addEditDialog)?_c('app-image-uploader',{ref:"appImageUploader",attrs:{"width":"150px","height":"150px","classes":"rounded-pill","parent-classes":"justify-center","label":"Company logo","parent-image-url":_vm.formData.logo},on:{"image-selected":_vm.handleImageSelect,"is-image-updated":function($event){_vm.formData.logo_updated = $event}}}):_vm._e()],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Name'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Activity')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('Activity'),"outlined":"","rows":"3","dense":"","no-resize":"","error-messages":errors},model:{value:(_vm.formData.activity),callback:function ($$v) {_vm.$set(_vm.formData, "activity", $$v)},expression:"formData.activity"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Company Type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":("* " + (_vm.$t('Company Type'))),"items":_vm.companyTypesList,"outlined":"","dense":"","error-messages":errors},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Press enter to create to record'))+" ")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.formData.company_type_id),callback:function ($$v) {_vm.$set(_vm.formData, "company_type_id", $$v)},expression:"formData.company_type_id"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Governorate'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":("* " + (_vm.$t('Governorate'))),"items":_vm.governorateList,"outlined":"","dense":"","error-messages":errors},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Press enter to create to record'))+" ")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.formData.governorate_id),callback:function ($$v) {_vm.$set(_vm.formData, "governorate_id", $$v)},expression:"formData.governorate_id"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('First Bank'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":("* " + (_vm.$t('First Bank'))),"items":_vm.banksList,"outlined":"","dense":"","error-messages":errors},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Press enter to create to record'))+" ")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.formData.first_bank_id),callback:function ($$v) {_vm.$set(_vm.formData, "first_bank_id", $$v)},expression:"formData.first_bank_id"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Account Bank'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":("* " + (_vm.$t('Account Bank'))),"items":_vm.banksList,"outlined":"","dense":"","error-messages":errors},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Press enter to create to record'))+" ")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.formData.account_bank_id),callback:function ($$v) {_vm.$set(_vm.formData, "account_bank_id", $$v)},expression:"formData.account_bank_id"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Business Name Date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('app-date-picker',{attrs:{"label":_vm.$t('Business Name Date'),"base-value":_vm.formData.name_date,"no-title":"","errors":errors,"failed":failed},on:{"value-changed":function($event){_vm.formData.name_date = $event}},model:{value:(_vm.formData.name_date),callback:function ($$v) {_vm.$set(_vm.formData, "name_date", $$v)},expression:"formData.name_date"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Capital'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":("* " + (_vm.$t('Capital'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.capital),callback:function ($$v) {_vm.$set(_vm.formData, "capital", $$v)},expression:"formData.capital"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Registration Number')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Registration Number'),"outlined":"","dense":"","type":"tel","error-messages":errors},model:{value:(_vm.formData.company_registration_number),callback:function ($$v) {_vm.$set(_vm.formData, "company_registration_number", _vm._n($$v))},expression:"formData.company_registration_number"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Company Registration Date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('app-date-picker',{attrs:{"label":_vm.$t('Company Registration Date'),"base-value":_vm.formData.company_registration_date,"no-title":"","hide-details":!failed,"errors":errors,"failed":failed},on:{"value-changed":function($event){_vm.formData.company_registration_date = $event}},model:{value:(_vm.formData.company_registration_date),callback:function ($$v) {_vm.$set(_vm.formData, "company_registration_date", $$v)},expression:"formData.company_registration_date"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Manager')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Manager'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.manager),callback:function ($$v) {_vm.$set(_vm.formData, "manager", $$v)},expression:"formData.manager"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Phone'))),"outlined":"","dense":"","type":"tel","error-messages":errors},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Email'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Website')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Website'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.website),callback:function ($$v) {_vm.$set(_vm.formData, "website", $$v)},expression:"formData.website"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Dos Number In Companies'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Dos Number In Companies'))),"outlined":"","dense":"","type":"tel","error-messages":errors},model:{value:(_vm.formData.dos_number_in_co),callback:function ($$v) {_vm.$set(_vm.formData, "dos_number_in_co", _vm._n($$v))},expression:"formData.dos_number_in_co"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Dos Number Place'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":("* " + (_vm.$t('Dos Number Place'))),"items":_vm.dosNumberPlacesList,"item-text":function (item) { return _vm.$t(item.text); },"outlined":"","dense":"","error-messages":errors,"menu-props":{ offsetY: true }},model:{value:(_vm.formData.dos_number_place),callback:function ($$v) {_vm.$set(_vm.formData, "dos_number_place", _vm._n($$v))},expression:"formData.dos_number_place"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Dos Number In Companies/General')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Dos Number In Companies/General'),"outlined":"","dense":"","type":"tel","error-messages":errors},model:{value:(_vm.formData.dos_number_in_co_general),callback:function ($$v) {_vm.$set(_vm.formData, "dos_number_in_co_general", _vm._n($$v))},expression:"formData.dos_number_in_co_general"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Dos Number In Tax Office')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Dos Number In Tax Office'),"outlined":"","dense":"","type":"tel","error-messages":errors},model:{value:(_vm.formData.dos_number_in_tax_office),callback:function ($$v) {_vm.$set(_vm.formData, "dos_number_in_tax_office", _vm._n($$v))},expression:"formData.dos_number_in_tax_office"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Real Beneficiary Form Number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Real Beneficiary Form Number'))),"outlined":"","dense":"","type":"tel","error-messages":errors},model:{value:(_vm.formData.real_beneficiary_form_number),callback:function ($$v) {_vm.$set(_vm.formData, "real_beneficiary_form_number", _vm._n($$v))},expression:"formData.real_beneficiary_form_number"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Social Security Employee Number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Social Security Employee Number'))),"outlined":"","dense":"","type":"tel","error-messages":errors},model:{value:(_vm.formData.social_security_number_employee),callback:function ($$v) {_vm.$set(_vm.formData, "social_security_number_employee", _vm._n($$v))},expression:"formData.social_security_number_employee"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('SL')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('SL'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.sl),callback:function ($$v) {_vm.$set(_vm.formData, "sl", $$v)},expression:"formData.sl"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('FCC')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('FCC'),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.fcc),callback:function ($$v) {_vm.$set(_vm.formData, "fcc", $$v)},expression:"formData.fcc"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Company Business No.')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Company Business No.'),"outlined":"","dense":"","type":"tel","error-messages":errors},model:{value:(_vm.formData.company_business_no),callback:function ($$v) {_vm.$set(_vm.formData, "company_business_no", _vm._n($$v))},expression:"formData.company_business_no"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Unique Company Business No.')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Unique Company Business No.'),"outlined":"","dense":"","type":"tel","error-messages":errors},model:{value:(_vm.formData.manager_shareholder_business_no),callback:function ($$v) {_vm.$set(_vm.formData, "manager_shareholder_business_no", _vm._n($$v))},expression:"formData.manager_shareholder_business_no"}})]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('app-form-tip'),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","outlined":"","small":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","depressed":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdil-check ")]),_c('span',{staticClass:"pe-2"},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('Save') : _vm.$t('Add'))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }